#location_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 26rem;
    margin-top: 10rem;
}
#location {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 900px;
}

#location_images {
    padding-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 310px);
    grid-template-rows: repeat(1, 235px);
    z-index: 2;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 39%;
}

#location_images div {
  padding: 10px;
}
#location_images img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
} 

#location_text{
  align-items: center;
  justify-content: center;
  position: relative;
  top: -15rem;
}


@media screen and (max-width: 720px) {
  #style_image {
    display: none;
  }

  #location_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 23rem;
    margin-bottom: 35rem;
  }

  #location {
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    width: 100%;
  }
  #location_text {
    position: absolute;
    width: 60%;
    transform: translate(-50%, -50%);
    top: -30rem;
    left: 50%;
  }

  #location_images {
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 92%;
    height: 350px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: -14rem;
    padding-top: 0;
    left: 50%;
    top: 0;
  }

  #location_images::-webkit-scrollbar {
    display: none;
  }

  #location_images img {
    height: 100%;
  }
  #location_images div { 
    height: 325px;
    min-width: 68%;
    max-width: 84%;
    display: inline-block;
  }
}
