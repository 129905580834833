button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

#first {
  transform: rotate(180deg);
}

#contact_button {
  font-family: 'Lato';
  font-weight: 600; 
  position: relative;
  height: 55px;
  background-color: #F15F3E;
  color: white;
  border-radius: 11px;
  padding: 0 30px;
  margin: 10px;
}

.style_img {
  height: 40px;
  width: 58px;
}

@media screen and (max-width: 720px) {
  #button-container{
    width: 400px;
  }

  #contact_button{
    padding: 0 1rem;
    margin: 0.5rem;
    min-width: 12rem; 
  }
}
