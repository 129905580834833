/*#camp{
}
*/
#camp {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 810px;
  margin-top: -37.5rem;
/*  display: inline-block;*/
}

#camp-img {
  padding-bottom: 40px;
}

@media screen and (max-width: 720px) {
  #camp {
    width: 95%;
    margin-top: -10.5rem
  }
  #camp-img {
    width: 70%;
  }
}

