.headerImage {
  width: 65%;
  position: absolute;
  display: block;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 43%;
  padding-bottom: 0;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

#content {
/*  transform: translate(0, 4%);*/
}

.col {
  flex-direction: column;
}

@media only screen  and (max-width: 1204px) and (min-width: 1000px){
    .headerImage {
    left: 50%;
    top: 35%;
  }
  
}


@media screen and (max-width: 720px) {
  .headerImage {
    width: 75%;
    left: 50%;
    top: 12%;
  }

}

a {
    all: unset;
}

p {
    margin: 0;
}