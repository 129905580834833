body{
    font-family: 'lato', sans-serif;
    
}



.top-navigation-bar{
    display: flex;
    justify-content: space-between;
    padding: 1rem 2.5rem;
    align-items: center;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    z-index: 10;
    top: 0;
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
}

nav{
    align-items: center;
    display:flex;
    gap: 1.5rem;
}


.nav-group{
    display: flex;
    gap: 1.5rem;
}

nav a{
    text-decoration: none;
    color: #24292B;
    font-weight: 700;
    cursor: pointer;
}



.whatsapp-button img{
    align-self: center;
    margin-right: 6px;
    height: auto;
    margin-bottom: -4px;
}

@media screen and (max-width: 720px) {

    .top-navigation-bar{
        height: 3rem;
        padding: .9rem;
        box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.15);
        background-color: rgba(255, 255, 255, 0.8);
        mix-blend-mode: normal;
        gap: .3rem;

    }


    .logo{
        width: 6rem;
    }
    nav{
        gap: 0.9rem;
    }

    .nav-group {
        display: flex;
        gap: 1rem;
    }

    nav a{
        font-size: .9rem;
    }
}