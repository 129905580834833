#activities_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#activities_card_continer {
    display: grid;
    padding: 1.25rem;
    grid-row: repeat(2, 39%);
    grid-template-columns: repeat(3, 34%);
    align-items: center;
    row-gap: 2rem;
}

#a_style {
  z-index: -1;
  position: relative;
  left: -450px;
  top: 75px;
}

#a_C {
  
  background-color: white;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
  border-radius: 1em;
  max-width: 1028px;
  box-sizing: border-box;
  padding: 1rem;
}

#activity_description {
  font-family: 'lato', sans-serif;
  line-height: 1.7rem;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  width: 62%;
  color: #3E4F55;
  padding: 1.5rem;
  padding-bottom: 0;
}

@media screen and (max-width: 720px){
  #activities_section {
    padding: 40px 0 0;
    width: 100%;
  }
  #activities_card_continer {
    justify-content: center;
    display: inline-block;
    width: 88%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  #activities_card_continer::-webkit-scrollbar{
    display: none;
  }


  #activity_description {
    width: 90%;
    padding: 1.5rem 0 0 1.5rem;
    padding-bottom: 0;
  }
  #a_style {
    display: none;
  }

  #a_C {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background: none;
    box-shadow: none;
    padding: 0;
  }


  #activity_text {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  #activity_text img {
    height: 4.6rem;
    padding-bottom: 1.25rem;
  }
}

  