#features {
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 14rem;
}

  #feature_text {
    margin-bottom: 1.5rem;
  }

#feature_container {
  display: flex;
  width: 75%;
  padding-top: 2rem;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 720px) {
  #features {
    margin-top: 60px;
  }
  #feature_container {
    width: 100%;
    flex-direction: column;
    gap: 2rem;
  } 
  #feature_text {
    width: 75%;
    margin-bottom: 1.5rem;
  }
}