#activity {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  color: #24292B;
  white-space: initial;
}
#activity_card {
  padding: 8px;
}

#activity_card p {
  margin: 10px 0 0 0 ;
  padding-bottom: 0;
}
#number { 
  margin: 0;
  font-weight: 800;
  font-size: 1.5rem;
  color: #11B0A1;
  font-family: 'Lato', sans-serif;
}
#price {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #5D7781;
}

@media screen and (max-width: 720px) {
  #activity_card {
    display: inline-block;
  }
  #activity_card div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
   #activity {
    max-width: 310px;
  }
}
