#location_details {
  display: flex;
/*  position: relative;*/
}

#style_image {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 15rem;
    left: 20%;
    width: 50px;
    height: 40px;
}
#location_card_container {
  margin: 0 35px 0 10px;
  z-index: 1;
}
#location_card {
    display: flex;
    gap: 2rem;
    background-color: white;
    padding: 2rem;
    box-shadow: 0px 4px 60px rgb(0 0 0 / 8%);
    border-radius: 16px;
    padding-top: 12rem;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: -2;
    left: 50%;
    top: 15.5rem;
    width: 670px;
}

#location_card div {
  top: 100px;
}

#style {
    position: absolute;
    z-index: -4;
    width: 180px;
    height: 110px;
    transform: translate(-50%, -50%);
    top: 27.8rem;
    right: 13%;
}

.mobile_map_image{
  display: none;
}

#map_button {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  background-color: #FAFEFF;
  padding: 12px 30px;
  font-size: 14px;
  color: #24292B;
  position: relative;
  top: -30%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
}

#l_heading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #373F42;
  margin-bottom: 1rem;
}

#l_data {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #3E4F55;
}

@media screen and (max-width: 720px) {
  #style {
    display: none;
  }
  #location_card {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding-top: 2rem;
    top: 16rem;
    box-sizing: border-box;
    left: 50%;
  }

  .mobile_map_image{
    display: block;
    max-width: 100%;
  }

  .map_image{
    display: none;
  }

  #map_button {
    display: block;
    top: -46px;
  }
}