#footer {
  position: relative;
  font-family: 'lato', sans-serif;
  color: #FFFFFF;
  padding: 30px 0 ;
  background: #373F42;
  justify-content: space-evenly;
  margin-top: 6rem;
}

#logo {
  padding: 0 3px;
  background: #373F42;
}

.footer-div {
  justify-content: flex-start;
  align-items: start;
  min-width: 150px;
  max-width: 280px;
  font-size: 14px;
}

#footer ul {
  cursor: pointer;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  list-style-type: none;
}

#footer li {
  padding: 4px 0;
}

.business_email{
  top: -4px;
  display: inline;
}

#s_text {
  font-size: 10px;
}

#copyright {
  font-size: 12px;
}
.footer_icon {
  position: relative;
  top: 4px;
  left: -5px;
}

@media screen and (max-width: 720px) {
  #footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10%;
    margin-top: 2rem;
  }

  .footer-div {
    max-width: 250px;
  }
}


.copyAlert {
  opacity: 0;
}

@keyframes disappear {
  0% {
    opacity: .25;
  }
  25% {
    opacity: 1;
  }

  70% {
    opacity: 0.25;
  }

  100% {
    opacity: 0;
  }
}