#head {
  width: 100%;
  position: relative;
  z-index: -1;
}
#head img {
  object-fit:cover;
  width: 100%;
  height: 100%;
}
/*#backgroundSize {
  visibility: hidden;
}*/
@media screen and (max-width: 720px) {
  #head img {
    height: 80%;
  }
}


