#tours_continer {
  display: grid;
  font-family: 'Lato';
  grid-template-columns: repeat(4, 1fr);
  padding: 3rem 0 2.5rem 0;
}
#tours_continer p {
  padding-top: 10px;
  margin: 0;
}
#tours_continer img {
  width: auto;
  height:220px;
  max-width: 250px;
  border-radius: 3%;
  object-fit: cover;
}
.sta {  
  color: #3E4F55;
}

#tour_price {
  font-style: normal;
  font-weight: 700;
  font-size: 18.51px;
  line-height: 22px;
  text-align: center;
  color: #3E4F55;
}
.tour_head {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #24292B;
}

.tour {
  margin: 100px 0 25px 100px;
}

.tour_card {
  padding-right: 1rem;
}

@media screen and (max-width: 720px) {
  #tours_continer {
    display: flex;
    height: 350px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 3rem 2rem;
    gap: 2rem;
  }
  #tours_continer::-webkit-scrollbar {
    display: none;
  }
  .tour_card {
    display: inline-block;
    padding: 0;
  }

  .tour {
    margin: 0;
    padding-bottom: 2.5rem;
  }
  .tour img {
    width: 60%;
  }
  .tour_contact {
    width: 100%;
  }
}
