#feature_component {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  height: 180px;
  width: 150px;
  padding: 0 3rem ;
}

#feature_component p {
  text-align: center;
  margin: 0;
}

#feature_head {
  font-family: 'lato', sans-serif;
  padding: 8px 0 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #323334;
}

#feature_data {
  font-family: 'lato', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #3E4F55;
}

@media screen and (max-width: 720px)  {
  #feature_component  {
    height: auto;
    padding-bottom: 10px;
  }

  .feature_icon{
    width: 4rem;
    align-self: center;
  }
}