#image-container {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(9, 72px);
  width: 810px;
  height: 360px;
}

.image {
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 10px;
  object-fit: cover;
}

#img1 {
  grid-row:   span 5;
  grid-column: span 5;
}

#img2 {
  grid-column-start:   6;
  grid-column-end:  10;
  grid-row-start:   1;
  grid-row-end:  4;
}

#img3 {
  grid-row: span 2;
  grid-column: span 2;
}

#img4 {
  cursor: pointer;
  position: relative;
  text-align: center;
  grid-row:  span 2;
  grid-column:  span 2;
}

#image-container img {
    width: 100%;
    height: 100%;
    border-radius: 3%;
}

#more {
    font-family: "Lato";
    font-weight: bold;
    color: #3E4043;
    font-size: 1rem;
    min-width: 6rem;
    border-radius: 0.5rem;
    position: absolute;
    background-color: white;
    opacity: .8;
    padding: 0.7rem 0.7rem;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    
}


@media screen and (max-width: 720px) {
  #image-container {
    width: 95%;
    height: auto;
    display: block;
  }
  .image {
    display: none;
  }
  .last {
    display: block;
  }
  #more {
    visibility: hidden;
    top: 80%;
  }

    #more:after {
    visibility: visible;
    content: "View More Images";
    font-family: "Lato";
    font-weight: bold;
    color: #3E4043;
    font-size: 1rem;
    min-width: 10rem;
    border-radius: 0.5rem;
    position: absolute;
    background-color: white;
    opacity: .8;
    padding: 0.7rem 0.7rem;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%
  }
}