#about {
  max-width: 100%;
}
#backgroundAbout {
  z-index: -2;
  position: relative;
}
#blackSpot {
  position: absolute;
  width: 80%;
  height: 100%;
  left: -25%;
  top: -15%;

background: linear-gradient(314.11deg, rgba(20, 30, 41, 0) 3.74%, #11202C 50.47%);
filter: blur(295px);
transform: rotate(18.59deg);
}
#about_container {
  width: 100%;
  overflow-x: hidden;
  gap: 1.5rem;
  margin-top: -12rem;
}

#backgroundAbout img {
  max-width: 100%;
/*  object-fit: cover;*/
}

  .mobile_abtUs_header{
    display: none;
  }

#AboutHeading {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 44%;
  left: 50%;
}

#about_container img{
  width: 50%;
}

#about_data {
  min-width: 34%;
  max-width: 19%;
  display: inline-block;
}
#about_heading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 22px;
  color: #1F2122;
  margin-bottom: 1em;
}
.paragraph {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  color: #3E4F55;
}
#about_details{
  font-size: 16px;
  line-height: 24px;
} 
#about_details_smaller {
  font-size: 14px;
  line-height: 22px;
}
#sider {
  z-index: -3;
  max-width: 10%;
/*  height: 100%;*/
}




@media screen and (max-width: 720px) {
  #backgroundAbout img{
    display: none;
    }
    #backgroundAbout .mobile_abtUs_header{
      max-width: 100%;
      display: block;
    }

    #blackSpot {
    display: none;
    position: absolute;
    width: 80%;
    height: 100%;
    left: -25%;
    top: -15%;
  }

  #AboutHeading {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 20%;
    left: 50%;
    width: 70%;
  }
#about_container {
    width: 100%;
    margin-top: -7rem;
}


  #about_container img{
    display: none;
  }

  #about_heading{
    margin-bottom: 1em;
  }

  #about_data {
    min-width: 86%;
    max-width: 21%;
  }

}